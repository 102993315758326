import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Hero = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              We make your world{' '}
              <Typography
                color={'primary'}
                component={'span'}
                variant={'inherit'}
                sx={{
                  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Safer, Smarter & more connected!
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={6}>
            {/* <Box
              component={Button}
              variant="contained"
              color="primary"
              size="large"
              height={54}
              marginTop={2}
              onClick={() =>
                document.getElementById('products').scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            >
              Products
            </Box> */}
          </Box>
          <Box
            paddingX={2}
            paddingY={1}
            bgcolor={'alternate.dark'}
            borderRadius={2}
          >
            <Typography variant="h6" component="p">
              At Ensecure Technologies, we are here to protect what matters most
              to you. We offer a wide range of technology solutions, from
              security cameras to home automation, all designed to make your
              life easier and safer.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        xs={12}
        md={6}
      >
        <Box
          component={'img'}
          loading="lazy"
          height={1}
          width={1}
          src={require('assets/illustrations/shield.png')}
          maxWidth={700}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
