import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { Chip, PaletteMode, SvgIcon } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const ThemeModeToggler = (): JSX.Element => {
  const theme = useTheme();
  const { themeToggler } = theme;
  const { mode } = theme.palette;

  interface Option {
    label: string;
    value: PaletteMode;
    icon: ReactElement;
  }
  const options: Option[] = [
    {
      label: 'Light',
      value: 'light',
      icon: (
        <SvgIcon fontSize="small">
          <LightModeIcon
            sx={{ color: mode === 'light' ? 'primary.main' : 'text.main' }}
          />
        </SvgIcon>
      ),
    },
    {
      label: 'Dark',
      value: 'dark',
      icon: (
        <SvgIcon fontSize="small">
          <DarkModeIcon
            sx={{ color: mode !== 'light' ? 'primary.main' : 'text.main' }}
          />
        </SvgIcon>
      ),
    },
  ];

  return (
    <>
      {options.map((option) => (
        <Chip
          icon={option.icon}
          key={option.value}
          label={option.label}
          size="small"
          onClick={() => themeToggler()}
          sx={{
            borderColor: 'transparent',
            // backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderWidth: 2,
            m: '5px 2px',
            pointerEvents:
              (option.value === 'light' && mode === 'light') ||
              (option.value !== 'light' && mode !== 'light')
                ? 'none'
                : 'auto',
          }}
        />
      ))}
    </>

    // <Button
    //   variant={'outlined'}
    //   onClick={() => themeToggler()}
    //   aria-label="Dark mode toggler"
    //   color={mode === 'light' ? 'primary' : 'secondary'}
    //   sx={{
    //     borderRadius: 2,
    //     minWidth: 'auto',
    //     padding: 0.5,
    //     borderColor: alpha(theme.palette.divider, 0.2),
    //   }}
    // >
    //   {mode === 'light' ? (
    //     <svg
    //       width={20}
    //       height={20}
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth={2}
    //         d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
    //       />
    //     </svg>
    //   ) : (
    //     <svg
    //       width={20}
    //       height={20}
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth={2}
    //         d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
    //       />
    //     </svg>
    //   )}
    // </Button>
  );
};

export default ThemeModeToggler;
