/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Overview = (): JSX.Element => {
  return (
    <Box
      bgcolor={'primary.main'}
      sx={{ opacity: '.8' }}
      borderRadius={2}
      paddingBottom={{ xs: 2, md: 0 }}
    >
      <Grid container data-aos="fade-up">
        {/* <Grid item container alignItems="flex-start" xs={12} md={4}>
          <Box
            component={'img'}
            src={require('assets/illustrations/bino.png')}
            alt="..."
            sx={{
              objectFit: 'cover',
              maxWidth: '90%',
              width: 200,
              padding: 5,
            }}
          />
        </Grid> */}
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={12}
          sx={{
            marginY: 2,
            paddingX: 2,
          }}
        >
          <Box marginBottom={2} marginTop={2}>
            {/* <Typography
              variant="h4"
              color="text.primary"
              align={'center'}
              sx={{ fontWeight: 700, color: 'common.white' }}
            >
              Find more products
            </Typography> */}
            <Typography
              align={'center'}
              sx={{ color: 'common.white', fontSize: '18px' }}
            >
              Our expertise covers everything from keeping an eye on your
              property with surveillance cameras to making your home smarter
              with automation systems. We also provide advanced solutions like
              AI based video analytics, IoT and cyber security solutions.
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item container justifyContent="flex-end" xs={12} md={4}>
          <Box
            component={'img'}
            src={require('assets/illustrations/drone.png')}
            alt="..."
            sx={{
              objectFit: 'contain',
              maxWidth: '90%',
              width: 200,
            }}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Overview;
