import React from 'react';

import { createRoot } from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga';
import './index.css';

ReactGA.initialize('G-WJLMLXWT98'); // Replace with your Tracking ID
ReactGA.pageview(window.location.pathname + window.location.search);

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// import reportWebVitals from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
