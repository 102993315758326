import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ListItemIcon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ThemeModeToggler from 'shared/components/ThemeModeToggler';
import InfoIcon from '@mui/icons-material/Info';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';

interface Props {
  pages: {
    general: Array<PageItem>;
  };
  onClose;
}

const SidebarNav = ({ onClose }: Props): JSX.Element => {
  const theme = useTheme();

  const { mode } = theme.palette;
  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          component={'img'}
          loading="lazy"
          height={1}
          width={1}
          src={require(mode === 'light'
            ? 'assets/illustrations/ENSLogo_W_Tagline_72dpi.png'
            : 'assets/illustrations/ENSLogo_Light.png')}
          alt="..."
          maxWidth={150}
        />
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <SecurityIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('products');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  80;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
              onClose();
            }}
            color="text.primary"
          >
            Products
          </Link>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <InfoIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('aboutus');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  100;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
              onClose();
            }}
            color="text.primary"
          >
            About
          </Link>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <SendIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('contactus');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  80;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
              onClose();
            }}
            color="text.primary"
          >
            Contact Us
          </Link>
        </Box>

        {/* <Box marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href={'/dashboard'}
            size="large"
            fullWidth
          >
            Login
          </Button>
        </Box> */}
        <Box marginTop={2}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{
              marginTop: 0,
              display: 'block',
              fontSize: '14px',
              fontWeight: 500,
              letterSpacing: '.08rem',
              lineHeight: '20px',
            }}
          >
            Color Scheme
          </Typography>
        </Box>
        <Box marginTop={1}>
          <ThemeModeToggler />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
