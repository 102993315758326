import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { ListItemIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    general: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const Topbar = ({
  onSidebarOpen,
  // pages,
  // colorInvert = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box alignItems={'center'} sx={{ display: 'flex' }}>
        <Box
          component={'img'}
          loading="lazy"
          height={1}
          width={1}
          src={require(mode === 'light'
            ? 'assets/illustrations/ENSLogo_W_Tagline_72dpi.png'
            : 'assets/illustrations/ENSLogo_Light.png')}
          alt="..."
          maxWidth={150}
        />
        {/* <Box
          display={'flex'}
          component={'a'}
          href="/"
          sx={{
            borderRadius: 1,
            minWidth: 'auto',
            padding: 1,
            background: theme.palette.primary.main,
            borderColor: alpha(theme.palette.divider, 0.2),
            marginRight: 1,
          }}
        >
          <CameraOutdoorIcon
            sx={{
              color: '#FFF',
              fontSize: '28px',
            }}
          />
        </Box> */}
        {/* <Divider orientation="vertical" flexItem /> */}
        {/* <Stack direction={'column'}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{
              marginTop: 0,
              marginLeft: 0.5,
              display: 'block',
              width: '100px',
              paddingTop: '4px',
              fontSize: '14px',
              fontWeight: 700,
              letterSpacing: '.03rem',
              lineHeight: '16px',
              textAlign: 'center',
            }}
          >
            ENSECURE TECHNOLOGIES
          </Typography>
        </Stack> */}
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        alignItems={'center'}
      >
        <Box display="flex" alignItems="center" sx={{ marginLeft: 4 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <SecurityIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('products');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  80;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
            }}
            color="text.primary"
          >
            Products
          </Link>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginLeft: 4 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <InfoIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('aboutus');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  100;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
            }}
            color="text.primary"
          >
            About
          </Link>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginLeft: 4 }}>
          <ListItemIcon sx={{ minWidth: '20px !important' }}>
            <SendIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          </ListItemIcon>
          <Link
            underline="none"
            component="a"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              const targetElement = document.getElementById('contactus');
              if (targetElement) {
                const targetPosition =
                  targetElement.getBoundingClientRect().top +
                  window.pageYOffset -
                  80;
                window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
                });
              }
            }}
            color="text.primary"
          >
            Contact Us
          </Link>
        </Box>
        {/* <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href={'/dashboard'}
            size="large"
          >
            Login
          </Button>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: {
            xs: 'flex',
            md: 'none',
          },
        }}
        alignItems={'center'}
      >
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
