/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTheme } from '@mui/material/styles';
const mock = [
  {
    color: colors.deepOrange[300],
    title: 'Physical Security Solutions',
    features: [
      'Boom barrier system',
      'Automatic sliding gates',
      'Guard Tour systems',
      'Gate Security Solutions',
      'X Ray  Baggage',
      'Under Vehicle Scanning System',
      'Automatic Number Plate Recognition Camera System',
      'Door Frame Metal Detector',
      'Full Body Scanner',
    ],
    subtitle: 'Choose thousands of Web design online course.',
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/gate.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  {
    color: colors.deepOrange[300],
    title: 'Electronic Security Solutions',
    features: ['Biometric System', 'CCTV Camera Solutions'],
    subFeaturesBiometric: [
      'Access Control',
      'Time Attendance',
      'Entrance Control System',
      'Visitor Management System',
      'Face Recognition Attendance System',
    ],
    subFeaturesCCtv: [
      'HD / IP CCTV',
      'AI Based Video Analytics',
      'Thermal Based Cameras',
      'Number Plate Recognition Camera',
      'Solar Camera',
      'AI Camera',
    ],
    subtitle: 'Choose thousands of Business analytics online course.',
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/cctv.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  {
    color: colors.deepOrange[300],
    title: 'Home  Solutions',
    features: [
      'Video Door Phones',
      'Smart LED Lights',
      'Gate Automation',
      'Home Automation',
      'Home Theatre Solutions',
      'IoT Based Solutions',
    ],
    subtitle: 'Choose thousands of photography online course.',
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/gatedSecurity.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  {
    color: colors.deepOrange[300],
    title: 'Audio Visual Solutions',
    subtitle: 'Choose thousands of Video creating online course.',
    features: [
      'Video Intercom',
      'EPABX',
      'Projectors',
      'Video Walls',
      'Video Conferencing Solutions',
      'Interactive Smart Board',
      'Digital Signage',
    ],
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/projector.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  {
    color: colors.deepOrange[300],
    title: 'IT Infrastructure Solutions',
    subtitle: 'Choose thousands of Health & fitness online course.',
    features: [
      'Structured Cabling',
      'Wireless Solutions',
      'Computers, Laptops and Tablets',
      'High End Servers',
      'NAS and SAN Storage',
      'Firewall',
      'Cyber Security Solutions',
    ],
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/rack.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  {
    color: colors.deepOrange[300],
    title: 'AI Based Software Solutions',
    subtitle: 'Choose thousands of marketing online course.',
    features: [
      'Video Management Software',
      'AI Based Video Analytics',
      'Face Recognition System',
      'Video Summarization',
    ],
    icon: (
      <Box
        component={'img'}
        src={require('assets/illustrations/ai.png')}
        sx={{
          maxWidth: 50,
        }}
      />
    ),
  },
  // {
  //   color: colors.orange[300],
  //   title: 'Network Security Solutions',
  //   subtitle: 'Choose thousands of finaces & sales online course.',
  //   icon: (
  //     <svg
  //       height={24}
  //       width={24}
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke="currentColor"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
  //       />
  //     </svg>
  //   ),
  // },
];

const Categories = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box id={'products'}>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <Box display={'flex'} flexDirection={'column'}>
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={alpha(item.color, 0.1)}
                      color={item.color}
                      variant={'rounded'}
                      borderRadius={2}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Grid container spacing={1}>
                      {item?.features?.map((feature, j) => (
                        <Grid item xs={12} key={j}>
                          <Box
                            component={ListItem}
                            disableGutters
                            // display="flex"
                            // alignItems="left"
                            width={'auto'}
                            padding={0}
                          >
                            <Box
                              component={ListItemAvatar}
                              minWidth={'auto !important'}
                              marginRight={2}
                            >
                              <Box
                                component={Avatar}
                                bgcolor={theme.palette.primary.main}
                                width={20}
                                height={20}
                              >
                                <svg
                                  width={12}
                                  height={12}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </Box>
                            </Box>
                            <ListItemText primary={feature}></ListItemText>
                          </Box>

                          {feature === 'Biometric System' &&
                            item?.subFeaturesBiometric.map((subFeature, k) => (
                              <Box
                                component={ListItem}
                                disableGutters
                                // display="flex"
                                // alignItems="left"
                                width={'auto'}
                                padding={0}
                              >
                                <Box
                                  component={ListItemAvatar}
                                  minWidth={'auto !important'}
                                  marginRight={2}
                                  marginLeft={3}
                                >
                                  <Box
                                    component={Avatar}
                                    bgcolor={theme.palette.primary.main}
                                    width={15}
                                    height={15}
                                  >
                                    <svg
                                      width={12}
                                      height={12}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </Box>
                                </Box>
                                <ListItemText
                                  key={k}
                                  primary={subFeature}
                                  primaryTypographyProps={{
                                    sx: { fontSize: '13px' },
                                  }}
                                />
                              </Box>
                            ))}
                          {feature === 'CCTV Camera Solutions' &&
                            item?.subFeaturesCCtv.map((subFeature, k) => (
                              <Box
                                component={ListItem}
                                disableGutters
                                // display="flex"
                                // alignItems="left"
                                width={'auto'}
                                padding={0}
                              >
                                <Box
                                  component={ListItemAvatar}
                                  minWidth={'auto !important'}
                                  marginRight={2}
                                  marginLeft={3}
                                >
                                  <Box
                                    component={Avatar}
                                    bgcolor={theme.palette.primary.main}
                                    width={15}
                                    height={15}
                                  >
                                    <svg
                                      width={12}
                                      height={12}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </Box>
                                </Box>
                                <ListItemText
                                  key={k}
                                  primary={subFeature}
                                  primaryTypographyProps={{
                                    sx: { fontSize: '13px' },
                                  }}
                                />
                              </Box>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Categories;
