import React from 'react';
import Box from '@mui/material/Box';
import Container from 'shared/components/Container';
import { Categories, FeaturedProducts, Hero, Overview } from './components';
import { Main } from 'shared/layouts';
import ProductCategories from './components/ProductCategories';
import Contact from './components/Contact';

const Ecommerce = (): JSX.Element => {
  return (
    <Main>
      <Container>
        <Hero />
      </Container>
      <Container paddingY={'0 !important'}>
        <Overview />
      </Container>
      <Container>
        <Categories />
      </Container>
      <Container>
        <ProductCategories />
      </Container>

      <Box bgcolor={'secondary.main'}>
        <Container>
          <FeaturedProducts />
        </Container>
      </Box>
      <Box bgcolor={'alternate.main'} marginTop={4}>
        <Container>
          <Contact />
        </Container>
      </Box>
      {/* <Container>
        <Products />
      </Container> */}
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <News />
        </Container>
      </Box> */}
      {/* <Container>
        <LatestProducts />
      </Container> */}
      {/* <Container paddingTop={'0 !important'}>
        <QuickSearch />
      </Container> */}
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <Reviews />
        </Container>
      </Box> */}
      {/* <Container>
        <Newsletter />
      </Container> */}
    </Main>
  );
};

export default Ecommerce;
