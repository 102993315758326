import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import Ecommerce from 'views/Ecommerce/Ecommerce';

const NotFound = React.lazy(() => import('views/NotFound'));

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Ecommerce />} />
      <Route path="*" element={<NotFound></NotFound>} />
    </ReactRoutes>
  );
};

export default Routes;
