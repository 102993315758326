const pages = {
  general: [
    {
      title: 'FAQ\'s',
      href: 'faq',
    },
    {
      title: 'Testimonials',
      href: 'testimonials',
    },
  ],
};

export default pages;
