import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = (): JSX.Element => {
  const theme = useTheme();
  const handleIconClick = (url) => {
    window.open(url, '_blank');
  };
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <Box
            display={'flex'}
            component={'a'}
            href="/"
            title="ENSECURE TECHNOLOGIES"
            sx={{
              borderRadius: 1,
              minWidth: 'auto',
              padding: 1,
              // background: theme.palette.primary.main,
              borderColor: alpha(theme.palette.divider, 0.2),
              marginRight: 1,
            }}
          >
            <Box
              component={'img'}
              loading="lazy"
              height={1}
              width={1}
              src={require(mode === 'light'
            ? 'assets/illustrations/ENSLogo_W_Tagline_72dpi.png'
            : 'assets/illustrations/ENSLogo_Light.png')}
              // src={require('assets/illustrations/ENS_Logo_forWeb_72dpi.png')}
              alt="..."
              maxWidth={150}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; {new Date().getFullYear()} Ensecure Technologies Pvt Ltd,
          Chennai, India, Inc.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop={0.5}
        >
          {/* <IconButton
            color={'primary'}
            onClick={() => handleIconClick('https://www.facebook.com')}
          >
            <FacebookIcon />
          </IconButton> */}
          <IconButton
            color={'primary'}
            onClick={() =>
              handleIconClick(
                'https://instagram.com/ensecuretechnologies?igshid=NGVhN2U2NjQ0Yg==',
              )
            }
          >
            <InstagramIcon />
          </IconButton>
          {/* <IconButton
            color={'primary'}
            onClick={() => handleIconClick('https://www.facebook.com')}
          >
            <LinkedInIcon />
          </IconButton> */}
          {/* <IconButton
            color={'primary'}
            onClick={() => handleIconClick('https://www.facebook.com')}
          >
            <YouTubeIcon />
          </IconButton> */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
