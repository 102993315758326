import React from 'react';
import Box from '@mui/material/Box';
import ThemeModeToggler from './ThemeModeToggler';

const TopNav = (): JSX.Element => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <ThemeModeToggler />
      </Box>
    </Box>
  );
};

export default TopNav;
