/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// const mock = [
//   {
//     title: 'Physical Security Solutions',
//     icon: (
//       <Box
//         component={'img'}
//         src={require('assets/illustrations/3d-flame-side-view-of-red-instant-camera.png')}
//         sx={{
//           maxWidth: 50,
//           // height: 'auto',
//           // position: 'absolute',
//           // bottom: '-164px',
//           // right: 0,
//           // display: { xs: 'none', sm: 'block' },
//         }}
//       />
//     ),
//   },
//   {
//     title: 'Electronic Security Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
//       </svg>
//     ),
//   },
//   {
//     title: 'Home Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path
//           fillRule="evenodd"
//           d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
//           clipRule="evenodd"
//         />
//       </svg>
//     ),
//   },
//   {
//     title: 'Audio Visual Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path
//           fillRule="evenodd"
//           d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
//           clipRule="evenodd"
//         />
//       </svg>
//     ),
//   },
//   {
//     title: 'IT Infrastructure Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
//       </svg>
//     ),
//   },
//   {
//     title: 'AI Based Software Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
//       </svg>
//     ),
//   },
//   {
//     title: 'Network Security Solutions',
//     icon: (
//       <svg
//         height={48}
//         width={48}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 20 20"
//         fill="currentColor"
//       >
//         <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
//       </svg>
//     ),
//   },
// ];

const Categories = (): JSX.Element => {
  return (
    <Box>
      <Box marginBottom={0}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'primary'}
          align={'center'}
        >
          OUR Comprehensive Offerings
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Together, we'll build a safer, more convenient world.
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          At Ensecure Technologies, we pride ourselves on keeping you seamlessly
          connected while prioritizing your safety and ensuring your utmost
          satisfaction by consistently delivering new technology with innovative
          products and one-stop solutions. Our services include monitoring,
          maintenance, inspection and several others.
        </Typography>
        {/* <Box marginTop={2} display={'flex'} justifyContent={'center'}>
          <Button variant="contained" color="primary" size="large">
            Product List(add the link)
          </Button>
        </Box> */}
      </Box>
      {/* <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={6} md={1.7} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  width={1}
                  height={1}
                  bgcolor={'alternate.main'}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <Box
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Box
                      width={50}
                      height={50}
                      bgcolor={'secondary.dark'}
                      borderRadius={'100%'}
                      sx={{
                        transform: `translate(${theme.spacing(
                          2,
                        )}, ${theme.spacing(-2)})`,
                        marginTop: 2,
                      }}
                    />
                    <Box
                      sx={{
                        color: 'primary.dark',
                        position: 'absolute',
                        bottom: 0,
                      }}
                    >
                      {item.icon}
                    </Box>
                  </Box>
                  <Typography
                    variant={'subtitle1'}
                    align={'center'}
                    sx={{ fontWeight: 500, marginTop: 2 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box> */}
    </Box>
  );
};

export default Categories;
