/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FeaturedProducts = (): JSX.Element => {
  return (
    <Box position={'relative'} id={'aboutus'}>
      <Grid container>
        <Grid item xs={12} sm={6} data-aos={'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{ fontWeight: 700, color: '#081621' }}
            >
              About Us
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              sx={{ color: '#081621' }}
            >
              Ensecure Technologies was established by a group of passionate
              professionals with a wealth of experience in security and
              technology. Our team of experts bring a ton of experience, skills,
              and commitment to every project, so you can be sure that we
              deliver excellence in every aspect of our services.
            </Typography>
          </Box>
          {/* <Box
            component={Button}
            variant="contained"
            color="primary"
            size="large"
            height={54}
          >
            Replace this
          </Box> */}
        </Grid>
      </Grid>
      <Box
        component={'img'}
        src={require('assets/illustrations/trustworthy.png')}
        sx={{
          maxWidth: 200,
          height: 'auto',
          position: 'absolute',
          bottom: '-164px',
          right: 0,
          display: { xs: 'none', sm: 'block' },
        }}
      />
    </Box>
  );
};

export default FeaturedProducts;
